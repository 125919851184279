<template>
    <main-modal @closeModal="$emit('closeModal')" :title="''">
            <div class="position-relative d-flex justify-content-center align-items-center" style="height:100px">
                <lottie :options="defaultOptions" :height="150" :width="150" class="position-absolute" style="margin-top: -40px;margin-bottom: -40px;"/>
            </div>
            <div class="text-center">
                <h2 class="title-22 weight-700 mb-4 mt-4">{{$t("cart.your_quotation_was_created")}}!</h2>
                <h5 class="text-capitalize size-18">{{$t('layout.hi')}}, {{user.entity.first_name | textFormat}} {{user.entity.last_name | textFormat}}</h5>
                <h4 class="subtitle-18 color-dark-gray-300 pb-1" style="max-width: 480px;margin: 0 auto;">
                    La cotización
                    <router-link :to="`/account/quotes`">
                        <span @click="$emit('closeModal')" class="main-link">#{{quotationID}}</span>
                    </router-link>
                    esta lista para facturar.
                </h4>
                <div class="d-flex justify-content-center mb-3 pt-4">
                    <router-link to="/account/quotes" class="d-flex" tag="div">
                        <second-button :title="'Cotizaciones'" class="me-3" :icon="true" @click="$emit('closeModal')" style="width:135px;">
                            <icon-list :color="'var(--main-navy)'" :size="12" class="me-2"/>
                        </second-button>
                    </router-link>
                    <main-button class="main-btn" :title="'Facturar'" :icon="true" @click="buyNow" style="width:135px;">
                        <icon-invoice :color="'#fff'" :size="12" class="me-2"/>
                    </main-button>
                </div>
            </div>
    </main-modal>
</template>

<script>
    import MainModal from '../../../../common/components/modals/MainModal.vue'
    import Lottie from "vue-lottie";
    import animationData from '../../../checkout/assets/animations/check-4';
    import SecondButton from '../../../../common/components/buttons/SecondButton.vue';
    import MainButton from '../../../../common/components/buttons/MainButton.vue';
    import { mapGetters } from 'vuex';
    import IconInvoice from '../../../account/assets/svg/iconInvoice.vue';
    import { quotesMixin } from '../../../account/helpers/mixins/quotes-mixin';
    import IconList from '../../../products/assets/svg/iconList.vue';
    import { cartMixin } from '../../../cart/helpers/mixins/cart-mixin';

    export default {
        components: { MainModal, Lottie, SecondButton, MainButton, IconInvoice, IconList },
        name: 'QuotationCreated',
        data(){
            return{
                defaultOptions: { animationData: animationData, loop: true },
            }
        },
        mixins: [quotesMixin, cartMixin],
        props:{
            quotationID:{
                type: String
            }
        },
        computed:{
            ...mapGetters({
                user:"auth/userData",
            }),
        },
        methods:{
            async buyNow(){
                // invoiceQuotation
                this.$emit('closeModal')
                const quotation = await this.$store.dispatch('account/getQuotationByID', {id:this.quotationID, refresh: false})
                await this.invoiceQuotation(quotation)

            }
        }
    }
</script>

<style scoped>
    button.main-btn:hover > span > div > svg{
        fill: white;
    }
    .modal-main-container::v-deep > .modal-main {
        min-width: 500px;
    }
</style>