<template>
    <icon-base viewBox="0 0 200 200" :width="size + 'px'" :fill="color">
        <path class="st0" d="M182.7,191.6L170.5,56.1c-0.3-3.7-3.4-6.5-7.1-6.5h-25.1V38.3C138.3,17.2,121.1,0,100,0S61.7,17.2,61.7,38.3  v11.3H36.6c-3.7,0-6.8,2.8-7.1,6.5L17.3,192.2c-0.2,2,0.5,4,1.9,5.5c1.4,1.5,3.3,2.3,5.3,2.3h151.1c4,0,7.2-3.2,7.2-7.2  C182.8,192.4,182.7,192,182.7,191.6z M76.1,38.3c0-13.2,10.7-23.9,23.9-23.9s23.9,10.7,23.9,23.9v11.3H76.1V38.3z M140.5,107.9  L94,154.4c-1.8,1.8-4.1,2.6-6.4,2.6c-2.3,0-4.6-0.9-6.4-2.6l-21.8-21.8c-3.5-3.5-3.5-9.2,0-12.7s9.2-3.5,12.7,0l15.4,15.4l40.1-40.1  c3.5-3.5,9.2-3.5,12.7,0C144,98.7,144,104.4,140.5,107.9z"/>
    </icon-base>
</template>

<script>
export default {
    name: 'iconBuy',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>

</style>
