<template>
    <main-confirm @confirm="$emit('confirm')" @cancel="$emit('closeModal')" confirm-title="cart.confirm_the_action" description="cart.description_confirm_the_action">
        <icon-warning :size="70"/>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../common/components/modals/MainConfirm.vue'
import IconWarning from '../../../common/svg/iconWarning.vue'
export default {
    components: { MainConfirm, IconWarning },
    name: "ConfirmCheckExistence"
}
</script>