<template>
    <icon-base :color="color" :size="size" viewBox="0 0 30 28">
        <g id="List_component" data-name="List component" transform="translate(0 10)">
            <rect id="Rectangle_761" data-name="Rectangle 761" width="8" height="8" rx="1"/>
            <rect id="Rectangle_762" data-name="Rectangle 762" width="19" height="8" rx="1" transform="translate(11)"/>
        </g>
        <g id="List_component-2" data-name="List component">
            <rect id="Rectangle_761-2" data-name="Rectangle 761" width="8" height="8" rx="1"/>
            <rect id="Rectangle_762-2" data-name="Rectangle 762" width="19" height="8" rx="1" transform="translate(11)"/>
        </g>
        <g id="List_component-3" data-name="List component" transform="translate(0 20)">
            <rect id="Rectangle_761-3" data-name="Rectangle 761" width="8" height="8" rx="1"/>
            <rect id="Rectangle_762-3" data-name="Rectangle 762" width="19" height="8" rx="1" transform="translate(11)"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconList',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
</style>

