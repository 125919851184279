<template>
    <div class="d-flex product-short-details" :class="[isCart ? 'align-items-between':'align-items-center']">
        <div v-if="wishlist" class="me-4">
            <div class="position-relative">
                <ribbon v-if="getSpecial(product) > 0"/>
            </div>
            <div class="container-img-product" @click="quickView(product)" :class="{'pe-none': confirmation}">
                <image-product class="img-product-details" :styleObject="{width: custom.width || '120px', height: custom.height || '120px', objectFit: 'contain'}" 
                :source="getImages(product.imageGallery)" />
                <div v-if="!confirmation" class="icon-eye d-none">
                    <icon-eye :size="30" color="white"/>    
                </div>
            </div>
        </div>
        <router-link v-else :to="{ name: 'product-detail', params: { id: product._id } }" :event="!confirmation ? 'click' : ''" tag="div" class="align-middle text-center cursor-pointer me-3">
            <div class="position-relative">
                <ribbon v-if="product.special_price > 0" />
            </div>
            <div class="container-img-product" @click="quickView(product)" :class="{'pe-none': confirmation}">
                <image-product class="img-product-details" :styleObject="{width: '120px', height: '120px', objectFit: 'contain'}" :source="getImages(product.imageGallery)" />
                <icon-warning :size="30" class="icon-warning d-none" />
                <div v-if="!confirmation" class="icon-eye d-none">
                    <icon-eye :size="30" :color="'white'"/>    
                </div>
            </div>
        </router-link>
        <div class="d-flex flex-column w-100">
            <div class="d-flex align-items-center justify-content-between" style="min-width: 170px;">
                <div class="container-product-description">
                    <router-link tag="h6" class="product-description title-16 my-1 cursor-pointer text-limited" :event="!confirmation ? 'click' : ''" :to="{ name: 'product-detail', params: { id: product._id }}" data-toggle="tooltip" data-placement="top" :title="product.description | textFormat"> 
                        [{{ product.codarticulo }}] 
                        {{ product.description | textFormat }}
                    </router-link>
                </div>
                <div class="d-flex align-items-center d-md-none ms-2" v-if="!wishlist">
                    <a href="#" class="btn-icon me-3" @click.prevent="increment(product)" v-if="!isCart && !confirmation" :class="[getQuantity(product) >= product.existence ? 'btn-disabled' : '']">
                        <icon-cart :size="21"/>
                    </a>
                    <a href="#" class="btn-icon me-3" @click.prevent="$emit('deleteConfirm', product)" v-if="isCart">
                        <icon-trash :size="20"/>
                    </a>
                    <a href="#" class="btn-icon" @click.prevent="addToWishList(product)" v-if="!confirmation">
                        <icon-favourite :size="18"/>
                    </a>
                </div>
            </div>
            <star-rating :rating="product.avgRating" readOnly :showRating="false" :starSize="18" class="mb-1" style="margin-top: -3px;"/>
            <!-- <div class="product-color-brand d-flex my-2">
                <div class="color">
                    <default-color />
                    <span class="subtitle-15 me-3">{{$t("product.color")}}: <b class="color-dark-gray-300 weight-500 ms-1">{{product.color ? (product.color) : 'N/a' | textFormat}}</b></span>
                </div>
                <span class="brand subtitle-15">{{$t("product.brand")}}: <b class="color-dark-gray-300 weight-500 ms-1">{{product.brand ? (product.brand) : 'N/a' | textFormat}}</b></span>
            </div> -->
            <div v-if="confirmation" class="d-flex">
                <h6 class="hide-mobile subtitle-15 pt-1 mb-0 d-block" v-if="product.special_price > 0 && product.quantity >= product.quantity_required_special_price">
                    {{$t("product.price")}} 
                    <del class="special-price weight-600 size-15 color-gray-1001 ms-1 me-2 through">{{product.price | moneyFormat}}</del> 
                    <b>{{product.special_price | moneyFormat}}</b>
                </h6>  
                <span v-else class="text-start size-15 mt-0 mt-md-2">
                    <b class="weight-600">{{product.price | moneyFormat}}</b>
                </span>
            </div>
            <div class="mt-1 d-flex justify-content-between align-items-center d-md-none" v-if="!wishlist">
                <product-quantity-input v-if="!confirmation" style="max-width: 100px;" :productQuantity="getQuantity(product)" :product="product" @deleteProduct="(data)=>{deleteConfirm(data)}" />
                <span class="subtitle-14" v-else>{{$t("common.quantity")}} {{getQuantity(product)}}</span>
                <span class="subtitle-14" v-if="getQuantity(product) == 0">{{showValueMoney()}}$ <b class="color-light-main-red weight-700">{{parseFloat(product.price).toFixed(2)}}</b></span>
                <span class="subtitle-14" v-else>{{showValueMoney()}}$ <b class="color-light-main-red weight-700">{{parseFloat((getQuantity(product)) * product.price).toFixed(2)}}</b></span>
            </div>
            <div v-if="isCart">
                <h6 v-if="!product.special_price" class="subtitle-14 mb-0 mt-2">
                    {{$t("product.price")}} 
                    <b class="ms-1 size-15">{{getPriceFromCart(product) | moneyFormat}}</b> 
                </h6>
                <div v-else-if="product.special_price" class="d-flex flex-column">
                    <h6 class="subtitle-14 mb-0">
                        {{$t("product.price")}} 
                        <del class="special-price size-15 color-dark-gray-200 ms-1 me-2">{{product.price | moneyFormat}}</del>
                        <b class="size-15 color-main-red">{{product.special_price | moneyFormat}}</b>
                    </h6>
                    <span class="weight-400 size-12 color-dark-gray-400">
                        {{ $t("product.valid_until.1") }}
                        {{ product.quantity_required_special_price }}
                        {{ $t("product.valid_until.2") }}
                    </span>
                </div>
            </div>
            <div v-if="isCart" class="d-flex mt-auto position-relative">
                <a @click.prevent="addNote = true" class="add-note-btn d-flex align-items-center main-link" style="height: 22px;">
                    <icon-edit :size="15" class="me-2" color="var(--blue-600)" />
                    <span class="size-14">{{$t("product.add_note")}}</span>
                    <half-circle-spinner v-if="loadingNote" :animation-duration="1000" :size="20" color="var(--blue-600)" class="d-inline-block ms-2" />
                </a>
                <popup v-if="addNote" id="add-note-modal" :style="{top: `35px`, left: `-8px`, width: `330px`, padding: `20px`}">
                    <h5 class="title-16 mb-0 weight-700 d-flex justify-content-between">
                        {{$t('product.add_note')}}
                    </h5>
                    <div class="d-flex justify-content-between size-14">
                        <span class="color-dark-gray-300">{{charactersLeft > 0 ? $t('cart.press_enter_to_save') : $t('cart.let_us_know_any_information')}}</span>
                        <div>
                            <span class="color-dark-gray-200 weight-100 me-2">{{charactersLeft}} / 150</span>
                            <a href="#" v-if="charactersLeft > 0" @click.prevent.stop="note = ''" class="weight-500 size-14">{{$t('cart.clear')}}</a>
                        </div>
                    </div>
                    <textarea v-model="note" id="review" @keypress.enter="addNote = false;" class="w-100 mt-2 px-3 py-1"
                    :placeholder="$t('product.report_textarea_placeholder')" name="review" rows="3" cols="50" maxlength="150"/>
                </popup>
                </div>
            </div>
        </div>
</template>

<script>
import ImageProduct from '../../products/components/Image.vue'

import { HalfCircleSpinner } from 'epic-spinners'
import StarRating from '../../products/components/rating/StarRating.vue'
import { productMixin } from '../../../modules/products/helpers/mixin/product-mixin';
import ProductQuantityInput from '../../products/components/ProductQuantityInput.vue';
import IconEdit from '../../checkout/assets/svg/iconEdit.vue';
import IconCart from '../../../common/svg/iconCart.vue';
import IconTrash from '../../../common/svg/iconTrash.vue';
import IconFavourite from '../../../common/svg/iconFavourite.vue';
import IconEye from '../../../common/svg/iconEye.vue';
import Ribbon from '../../products/components/Ribbon.vue';
import Popup from '../../../common/components/popup/Popup.vue';
import IconWarning from '../../../common/svg/iconWarning.vue';

export default {
    components: { ImageProduct, StarRating, HalfCircleSpinner, IconEdit, ProductQuantityInput, IconCart, IconTrash, IconFavourite, Ribbon, IconEye, Popup, IconWarning },
    name:"ProductShortDetails",
    props: {
        product: {
            type: Object,
            required: true
        },
        isCart: {
            type: Boolean
        },
        confirmation: {
            type: Boolean
        },
        wishlist: {
            type: Boolean
        },
        custom: {
            type: Object,
        }
    },
    computed: {
        charactersLeft() {
            var char = this.note.length,
                limit = 0;
            return (limit + char)
        },
    },
    data() {
        return {
            addNote: false,
            noteModalCounter: null,
            note: '',
            oldNote: '',
        }
    },
    mixins: [productMixin],
    watch: {
        addNote(val){
            if(!val) { //validating the note
                this.oldNote !== this.note ? this.saveNote(this.oldNote === '') : this.note = this.oldNote;
            }
            if(val) { //setter
                this.oldNote = this.note;
                this.noteModalCounter = setTimeout(() => {
                    document.body.addEventListener('click', this.handleOutsideNoteClick);
                }, 100);
            }else {
                document.body.removeEventListener('click', this.handleOutsideNoteClick);
            }
        }
    },
    methods: {
        quickView(product){
            if(!this.confirmation) this.$emit('showQuickView', product)
        },
        handleOutsideNoteClick(evt){
            let el = document.getElementById('add-note-modal');
            if (!(el == evt.target || el.contains(evt.target))) {
                this.addNote = false;
            }
        }
    },
    destroyed() {
        clearTimeout(this.noteModalCounter);
        document.body.removeEventListener('click', this.handleOutsideNoteClick);
    },
    created() {
        // BUG ADDING NOTE -> SEE LATER
        const product = this.getProducts.find(product => (product.cod_articulo ?? product.codarticulo) == this.product?.codarticulo);
        if(product?.note){
            this.note = product.note;
        }
        this.oldNote = this.note;
    },
}
</script>

<style scoped>
.img-product-details {
    border-radius: 7px;
    overflow: hidden;
}
.quantity-input-container div {
    height: 23px !important;
    width: 70px;
}
.btn-icon > svg {
    fill: var(--gray-1001);
}
.btn-icon:hover {
    fill: var(--light-main-red);
}
@media (max-width : 768px) {
    .product-description {
        margin-bottom: 0px;
    }
}
.container-product-description {
    max-width: 350px;
}
@media (max-width: 1400px) {
    .container-product-description{
        max-width: 270px;
    }
}
@media (max-width: 1200px) {
    .container-product-description{
        max-width: 200px;
    }
}
@media (max-width: 376px) {
    .container-product-description{
        max-width: 180px;
    }
}
@media (max-width: 353px) {
    .container-product-description{
        max-width: 170px;
    }
}
@media (max-width: 320px) {
    .container-product-description{
        max-width: 130px;
    }
}

@media (max-width: 360px) {
    .container-product-description{
        max-width: 160px;
    }
}
.popup::v-deep::before {
    top: 0;
    left: 9%;
    transform: translate(50%, -50%) rotate(314deg);
}
textarea:focus-visible{
    outline: none;
}
textarea {
    border-color: #cedde9 !important;
    border-radius: 7px !important;
    font-size: 14px;
}
textarea::placeholder{
    color: var(--dark-gray-200);
    font-weight: 100;
    font-size: 14px;
}
.container-img-product:hover .image__wrapper::v-deep > img{
    transform: scale(1.2);
}
.container-img-product{
    position: relative;
    border-radius: 7px;
    border: 1px solid var(--light-gray-100);
    transition: var(--transition-1);
}
.container-img-product:hover::before {
    cursor: pointer;
    content: '';
    background: #212e3d80;
    position: absolute;
    width: 100%;
    z-index: 2;
    height: 100%;
    animation: show-mask 0.3s;
    left: 0;
    border-radius: 7px;
}
.container-img-product:hover > .icon-eye{
    display: flex !important;
}
.icon-eye {
    background: var(--main-red);
    height: 40px;
    width: 40px;
    padding: 10px;
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    left: 32%;
    top: 34%;
    animation: show-eye 0.3s;
    cursor: pointer;
}
.add-note-btn > svg {
    transition: all .05s ease-in-out;
}
.add-note-btn > span {
    color: var(--blue-600);
    transition: var(--transition-1);
}
.add-note-btn:hover > span {
    color: var(--main-red);
}
.icon-warning{
    z-index: 2;
    position: absolute;
    bottom: -10px;
    right: -15px;
}
</style>
