<template>
    <main-confirm @confirm="$emit('confirm')" @cancel="$emit('closeModal')" :confirm-title="title" :buttonConfirmTitle="'Continuar'" :description="description" :contentHTML="contentHTML">
        <icon-warning :size="60"/>
    </main-confirm>
</template>
<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue'
import IconWarning from '../../../../common/svg/iconWarning.vue'


export default {
    name: "ErrorCart",
    components: { MainConfirm, IconWarning, },
    props:{
        title: {
            type: String
        },
        description: {
            type: String
        },
        contentHTML:{
            type: String
        }
    }
}
</script>