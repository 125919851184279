<template>
    <div>
        <h3 class="title-24 weight-700">{{$t("cart.check_existence")}}</h3>
        <div>
            <div style="min-height: 92px;">
                <product-short-details :product="products[page].product" :key="imageProduct"/>
            </div>
            <table class="table mt-3">
                <thead style="border-bottom: 2px solid #dee2e6;border-top: 1px solid #dee2e6;">
                    <tr>
                        <th class="title-14 mb-0 p-1">{{$t("cart.branch")}}</th>
                        <th class="title-14 mb-0 p-1 text-center">{{$t("cart.quantity_available")}} </th>
                        <th class="title-14 mb-0 p-1 text-center">{{$t("cart.buy")}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in existence[page]" :key="index">
                        <td class="subtitle-13 color-dark-gray-300 align-middle">{{item.description}}</td>
                        <td class="subtitle-13 color-dark-gray-300 align-middle  text-center">{{item.existencia}}</td>
                        <td class="subtitle-13 color-dark-gray-300 align-middle">
                            <div class="d-flex justify-content-center">
                                <product-quantity-input :productQuantity="getQuantity(products[page])" :product="products[page]" style="max-width: 130px; border-radius: 20px;"/>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex aling-items-center " :class="[products.length > 1 && page != 0? 'justify-content-between': 'justify-content-end']">
            <second-button :title="'cart.back'" @click="page--" v-if="page != 0"/>
            <main-button :title="'cart.next'" @click="page++" v-if="products.length > page + 1"/>
            <main-button :title="'cart.close_all'" v-else/>
        </div>
    </div>
</template>

<script>
import ProductQuantityInput from '../../products/components/ProductQuantityInput.vue'
import ProductShortDetails from '../components/ProductShortDetails.vue'
import { productMixin } from '../../../modules/products/helpers/mixin/product-mixin'
import MainButton from '../../../common/components/buttons/MainButton.vue'
import SecondButton from '../../../common/components/buttons/SecondButton.vue'

export default {
    components: { ProductShortDetails, ProductQuantityInput, MainButton, SecondButton },
    name: "CheckExistence",
    props:{
        products:{
            type: Array
        },
        existence:{
            type: Array
        }
    },
    mixins:[productMixin],
    data() {
        return {
            page: 0,
            imageProduct: 0
        }
    },
    watch:{
        page(){
            this.imageProduct += 1
        }
    }
}
</script>

<style scoped>
    @media (min-width: 768px) {
        table{
            min-width: 63vh
        }
    }
</style>
