import { CartService } from "@/modules/cart/services/cart-service";
import { mapGetters } from "vuex";
import { http } from "../../../../common/services/web-backend";
const service = new CartService();

export const quotesMixin = {
    computed:{
        ...mapGetters({
            isPageDisable: "common/isPageDisable",
            changedQuotes: "account/changedQuotes",
            user:"auth/userData",
            configurations: "common/configurations",
            employeeActiveCode: 'auth/employeeActiveCode',
            employee: 'auth/employee',
        })
    },
    methods:{
        async moveToCart(quotation, isSame) {
            if(this.selectedQuotation && !isSame){
                let errorData = {
                    show: true,
                    title: `Existe una cotizacion en el carrito.`,
                    contentHTML: 
                    `<div class="text-center" style="line-height: 18px;">
                        Moviste previamente la cotizacion
                        <a>${quotation.order_token_id}</a>
                        al carrito. <br> 
                        <span class="color-dark-gray-300 size-14">
                            Primero debes guardar los cambios o desengancharla.
                        </span>
                    </div>`
                }
                this.$store.commit('common/SHOW_MODAL_ERROR', errorData)
                return
            }
            let state = this.getState(quotation.quote_status_id)
    
            if(state.id != 3){
                let notify = {
                    type: 'warning',
                    show: true,
                    contentHTML: 
                        `<span class="color-dark-gray-200">
                            La cotizacion ya fue 
                            <b class="weight-500">¡${state.description.toUpperCase()}!</b>
                        </span>`
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                return
            }
            let productsWithQuantity = []
            for (let i = 0; i < quotation.order_details.length; i++) {
                const {product, quantity} = quotation.order_details[i];
                productsWithQuantity.push({cod_articulo: product.codarticulo, quantity: quantity})
            }
            try {
                this.loading = {active: true, quotation: quotation.order_token_id}
                // if(!this.employeeActiveCode)
                if(this.user.entity.activated_employee && this.employeeActiveCod){
                    await this.$store.dispatch("auth/loginCustomer", {customer: quotation.customer_code, getCart: false})
                }
                // await this.$store.dispatch("auth/loginCustomer", {customer: quotation.customer_code, getCart: false})
                this.$store.commit("branches/SET_BRANCH", quotation.branch_code)
                await this.$store.dispatch('cart/clearCart');
                const response = await service.syncMyCartLocal(productsWithQuantity, quotation.branch_code);
                let quantity = 0
                response.products.forEach(product => {
                    quantity += product.quantity
                    
                });
                this.$store.commit("cart/SET_CART", response)
                this.$store.commit("cart/SET_PRODUCTS", response.products)
                this.$store.commit('account/SELECT_QUOTATION', quotation.order_token_id)
                let data = { type: 'buyAgain', title: 'account.products_added_to_cart', show: true, quantity: quantity, product: quotation.order_details}
                this.$store.commit("common/SET_NOTIFY", data)
                this.$router.push({ path:  '/cart'}).catch(() =>{ });
            } catch (error) {
                console.log(error)
                this.$store.commit('common/SHOW_MODAL_ERROR', {
                    show: true, 
                    title: `Ocurrio moviendo la cotización al carrito.`, 
                    description: error?.data?.message ?? error.message
                })
            } finally{
                this.loading = {active: false,quotation: null}
            }
        },
        getState(id){
            const status = [
                {id: 1, description: 'Anulada', value: 'cancel'},
                {id: 2, description: 'Facturada', value: 'invoiced'},
                {id: 3, description: 'Pendiente', value: 'pending'},
            ]
            return status.find(state=>state.description == id)
        },
        async invoiceQuotation(quotation){
            if(this.user.entity.created_from == "ECOMMERCE"  && this.configurations.general.web_page.validate_phone_on_checkout){
                const data = await http.post("users/is-phone-number-validated");
                if(!data.data.data.is_phone_number_validated){
                    return this.$store.commit('auth/OPEN_MODAL_NUMBER', true)
                }
            }

            const state = this.getState(quotation.quote_status_id)
            
            if (state.id != 3) return
            
            await this.$store.dispatch("common/getConfigurations");
            const validateCustomer = !this.user.entity.activated_employee && this.configurations.general.web_page.active_for_customer && !this.employeeActiveCode
            const validateEmployee = this.employee?.entity?.activated_employee && this.configurations.general.web_page.active_for_employee
            
            if(this.isPageDisable || validateCustomer || validateEmployee){
                this.$store.commit("common/SET_DISABLE_MODAL", true);
                return;
            }
            try {
                this.$store.commit("common/SET_STATE_LOADING", true)
                // await this.$store.dispatch("auth/loginCustomer", {customer: quotation.customer_code, getCart: false})
                if(this.user.entity.activated_employee && this.employeeActiveCode){
                    await this.$store.dispatch("auth/loginCustomer", {customer: quotation.customer_code, getCart: false})
                }
                if (quotation.is_shipping == 'yes') {
                    this.$store.commit("checkout/SET_DELIVERY_QUOTATION", quotation.address.address_id);
                    this.$store.commit('account/SET_ADDRESS', quotation.address.address_id);
                } 
                
                this.$store.commit('account/SELECT_QUOTATION', quotation.order_token_id)
                this.$store.commit("cart/SET_CART", {total_amount: quotation.amount, total_items_products: quotation.number_of_items})
                this.$store.dispatch('cart/setProducts', quotation.order_details)
                await this.$store.dispatch("account/getAddresses")
                // QT00065250
                await this.buy()

            } catch (error) {
                await this.$store.dispatch('cart/getCartByBranch', {codplace: this.branchSelected.codplace}); 
                this.$store.commit('account/SELECT_QUOTATION', null)

                const errorData = {
                    show: true,
                    title: `Productos con problemas.`,
                    contentHTML: error.code == 8400015 ? 
                    `<div class="text-start color-dark-gray-300">
                        <h6 class="size-14 mb-3 weight-400">${this.$options.filters.textFormat(error.message)}.</h6>
                        <table class="table">
                            <thead class="bg-light-gray-100">
                                <tr class="size-14 color-dark-gray-200 weight-400">
                                    <td scope="col">Codigo</td>
                                    <td scope="col">Producto</td>
                                    <td scope="col">Mensaje</td>
                                    <td scope="col" class="text-center">Existencia</td>
                                    <td scope="col" class="text-center">Cantidad</td>
                                </tr>
                            </thead>
                            <tbody style="border-top: 0px">
                            ${error.data.products_error[quotation.branch_code].map(({product, error_code, quantity}) =>
                                `<tr class="size-14">
                                    <th>${product.codarticulo}</th>
                                    <td>${this.$options.filters.textFormat(product.description)}</td>
                                    <td class="color-dark-gray-200">${error_code == 8400106 ? 'La existencia no es valida.' : ''}</td>
                                    <td class="text-center ${product.existence < quantity ? 'color-main-red' : ''}">${product.existence}</td>
                                    <td class="text-center">${quantity}</td>
                                </tr>`
                            ).join('')}
                            </tbody>
                        </table>
                        </div>` : 
                        `<h6 class="text-center size-14 color-dark-gray-200">${this.$options.filters.textFormat(error.message)}</h6>`
                    }
                this.$store.commit('common/SHOW_MODAL_ERROR', errorData)
                this.$store.commit("common/SET_STATE_LOADING", false)
            }
        },
        async createQuotation(){
            try {
                if(this.products.length == 0)return
                this.loading = true
                await this.$store.dispatch("common/getConfigurations");
                const validateCustomer = !this.user.entity.activated_employee && this.configurations.general.web_page.active_for_customer && !this.employeeActiveCode
                const validateEmployee = this.employee?.entity?.activated_employee && this.configurations.general.web_page.active_for_employee
                if(this.isPageDisable || validateCustomer || validateEmployee){
                    this.$store.commit("common/SET_DISABLE_MODAL", true);
                    return;
                }
                if(this.selectedQuotation){
                    const quotation = this.changedQuotes.find(quotation=> quotation.quotation == this.selectedQuotation)
                    if(quotation){
                        const validate = quotation.products.filter(item=> item.delete == true)
                        if(validate.length > 0){
                            quotation.products.forEach(() => {
                                const index = this.products.findIndex(e => e.delete == true)
                                if(index == -1){
                                    this.products.splice(index, 1)
                                }
                            });
                        }
                    }
                    let params = {
                        products: this.products,
                        id: this.selectedQuotation,
                        branch: this.branchSelected.codplace
                    }
                    await this.$store.dispatch('account/updateQuotationFromCart', params)
                    this.$store.dispatch('cart/clearCart')
                    let notify = {
                        show: true,
                        type: 'success',
                        contentHTML: 
                        `   <span class="size-14">
                                La cotizacion <a>${this.selectedQuotation}</a> ha sido actualizada con éxito.
                            </span>
                        `
                    }
                    this.$store.commit("common/SET_SHORT_NOTIFY", notify);
                    this.$store.commit('account/SELECT_QUOTATION', null)
                    return
                }
                const response = await this.$store.dispatch('cart/createQuotation');
                // this.quoteCreated = { show: true, quotation: response.document_id }
                const quoteCreated = { 
                    show: true, 
                    quotation: response.document_id 
                }

                this.$store.commit('cart/SET_QUOTE_CREATED', quoteCreated)
                // this.$emit('quotationCreated', response.document_id)
                // let notify = {
                //     title: 'cart.notification.succesfull_quotation',
                //     type: 'success',
                //     show: true
                // }
                // this.$store.commit("common/SET_SHORT_NOTIFY", notify);
            } catch (error) {
                let errorData = {
                    show: true,
                    title: `Ha ocurrido un error.`,
                    description: error.response.data.message
                }
                this.$store.commit('common/SHOW_MODAL_ERROR', errorData)
            } finally{
                this.loading = false
            }
        },
    }
}