<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 99.996 92.644">
        <path id="Path_3243" data-name="Path 3243" d="M1041.7,1224.907l-9.037,3.724a46.32,46.32,0,1,0-5.309,47.478,6.019,6.019,0,0,0-9.554-7.323,34.358,34.358,0,1,1,3.635-35.533l-12.114,4.992a1.834,1.834,0,0,0-.008,3.39l22.861,9.518a1.835,1.835,0,0,0,2.4-.987l9.522-22.86A1.834,1.834,0,0,0,1041.7,1224.907Z" transform="translate(-944.242 -1201.636)"/>
    </icon-base>
</template>

<script>
export default {
name: 'iconRefresh',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: '#5a8cdb'
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>

<style scoped>
    /* .st14 {
        fill: #8cb24b;
    } */
    .st5 {
        fill: #ffffff;
    }
</style>
