<template>
  <main-modal @closeModal="$emit('closeModal')" :title="'cart.out_of_stock'" :loading="loading">
    <div v-if="!showCheckExistence">
      <alert-warning :show="true" class="mt-2 mb-4 p-1" @showAlert="showAlert = false" :size="40" :showCounter="false" :title="$t('cart.product_not_avilable_msg')"/>
      <div class="d-flex">
        <h6 class="mb-0 me-auto">{{$t("cart.reduce_the_quantity")}}.</h6>
        <h6 class="color-blue-600 size-14 hover-underline hover-main-red ms-auto">Eliminar sin cantidad</h6>
      </div>
      <div class="table-responsive table-product">
        <table class="table">
          <thead>
            <tr class="alert-quantity-tr">
              <th>{{$t("cart.product_details")}}</th> 
              <th class="text-center align-middle">{{$t("cart.ordered_quantity")}}</th>
              <th class="text-center align-middle">{{$t("cart.available")}}</th>
              <th class="text-center align-middle"></th>
            </tr>         
          </thead>
          <tbody>
            <tr v-for="product in products" :key="product.product.codarticulo">
              <td class="align-middle">
                <product-short-details :product="product.product || {}"/>
              </td>
              <td class="align-middle text-center">
                <product-quantity-input :productQuantity="getQuantity(product.product)" :product="product.product" @deleteProduct="(data)=>{deleteConfirm(data)}"
                style="max-width: 130px; border-radius: 20px;"/>
              </td>
              <td class="align-middle text-center">
                {{product.product.existence > 0 ? product.product.existence : 0}}
              </td>
              <td class="align-middle text-center position-relative">
                <sub class="color-main-red position-absolute sub-delete d-flex align-items-center" v-if="getProductsRemoved(product.product.codarticulo)"><icon-close-circle :color="'#DB3535'" class="me-2"/> Eliminado</sub>
                <icon-trash @click.native="selectedProduct = product.product; showConfirmDeleteProduct = true" :color="'var(--gray-1001)'" class="hover-icon cursor-pointer" :size="28" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <second-button id="save-changes" title="cart.save_changes" class="me-4" @click="$emit('closeModal')"/>
        <main-button id="check-existence" title="cart.check_existence" @click="confirmCheckExistence = true" v-if="false"/>
      </div>
      <confirm-check-existence v-if="confirmCheckExistence" @confirm="confirmCheck" @closeModal="confirmCheckExistence = false;"/>
    </div>
    <check-existence v-else :products="products" :existence="existence"/>
    <confirm-delete-product v-if="showConfirmDeleteProduct" @confirmDelete="deleteConfirmed" @closeModal="showConfirmDeleteProduct = false" :loading="loadingDelete"/>
    <main-notify v-if="notify.show" class="quantity-alert-notify" />
  </main-modal>
</template>

<script>
import AlertWarning from '../../../common/components/alerts/AlertWarning.vue';
import MainButton from '../../../common/components/buttons/MainButton.vue';
import SecondButton from '../../../common/components/buttons/SecondButton.vue';
import MainModal from '../../../common/components/modals/MainModal.vue';
import ProductQuantityInput from '../../products/components/ProductQuantityInput.vue';
import ProductShortDetails from '../components/ProductShortDetails.vue';
import CheckExistence from './CheckExistence.vue';
import { productMixin } from '../../../modules/products/helpers/mixin/product-mixin'
import ConfirmCheckExistence from './ConfirmCheckExistence.vue';
import ConfirmDeleteProduct from '../../products/components/modals/ConfirmDeleteProduct.vue';
import IconTrash from '../../../common/svg/iconTrash.vue';
import IconCloseCircle from '../../../common/svg/iconCloseCircle.vue';
import { mapGetters } from 'vuex'
import MainNotify from '../../../common/components/notifications/MainNotify.vue';


export default {
  name: "QuantityAlert",
  components: {
    MainModal,
    ProductShortDetails,
    ProductQuantityInput,
    MainButton,
    SecondButton,
    AlertWarning,
    CheckExistence,
    ConfirmCheckExistence,
    ConfirmDeleteProduct,
    IconTrash,
    IconCloseCircle,
    MainNotify
},
  props: {
    products: {
      type: [Object, Array]
    },
    existence:{
      type: Array
    },
    show:{
      type: Boolean,
      default: true
    }
  },
  mixins: [productMixin],
  data() {
    return {
      showCheckExistence: false,
      confirmCheckExistence: false,
      loading: false,
      showConfirmDeleteProduct: false,
      selectedProduct: {},
      productsRemoved: [],
      loadingDelete: false
    }
  },
  computed:{
    ...mapGetters({
      notify: "common/notify",
    }),
  },  
  watch:{
    async show(){
      if(this.show)
      await this.getUrlByProduct()
    }
  },
  methods: {
    async confirmCheck(){
      this.confirmCheckExistence = false;
      this.loading = true;
      for (let i = 0; i < this.products.length; i++) {
        const product = this.products[i];
        await this.$store.dispatch("cart/removeProduct", {cod_articulo: product.product.codarticulo, quantity: 0});
      } 
      this.loading = false;
      this.showCheckExistence = true;
    },
    deleteConfirm(product){
        this.selectedProduct = product
        this.showConfirmDeleteProduct = true
    },
    async deleteConfirmed() {
      try {
        this.loadingDelete = true;
        await this.$store.dispatch("cart/removeProduct", {cod_articulo: this.selectedProduct.codarticulo, quantity: 0});
        this.productsRemoved.push(this.selectedProduct)
        this.showConfirmDeleteProduct = false;
      } catch (error) {
        console.log(error)
      } finally{
        this.loadingDelete = true;
      }
    },
    getProductsRemoved(code){
      let find = this.productsRemoved.findIndex(product => product.codarticulo == code)
      if(find != -1)
      return true
    }
  },
  destroyed(){
      this.$store.commit('products/SET_QUICK_VIEW_STATE', false)
  } ,
  created(){
      this.$store.commit('products/SET_QUICK_VIEW_STATE', true)
  }
}
</script>

<style scoped> 
  .alert-quantity-tr {
    border-top: 2px solid var(--gray-50);
    border-bottom: 2px solid var(--gray-50);
    color: var(--dark-main-navy);
  }
  .product-short-details::v-deep > div > div > div {
    max-width: 200px;
  }
  .table-product {
    max-height: 461px;
    /* padding-right: 20px */
  }
  .quantity-input-container {
    max-width: 130px; 
    border-radius: 20px;
  }  
  .hover-icon:hover{
    fill: var(--light-main-red) !important;
  }
  .product-deleted{
    background-color: red;
  }
  .sub-delete{
    top: 0px;
    right: 0px;
    height: 57px;
  }
  .quantity-alert-notify{
    position: fixed !important; 
    right: 23px !important;

    top: 84vh !important;
    height: fit-content;
    border-radius: 6px;
    z-index: 13;
}
</style>
